html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: "Poppins";
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins-Bold"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Italic";
  src: local("Poppins-Italic"),
    url("../fonts/Poppins-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins SemiBold";
  src: local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  src: local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
}
