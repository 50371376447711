.bob {
    background-size: cover;
    height: 70px;
    width: 70px;
    position: absolute;
    margin-top: 3rem;
    will-change: transform;
    touch-action: none;
    overflow: hidden;
    margin-left: 20px;
    cursor: grab;
    z-index: 100;
}